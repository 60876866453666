// ------------------------------------
// Global Fonts.
// ------------------------------------

// When importing fonts from Google make sure the URL is HTTPS or protocol agnostic.
// Font Awesome.
// @import url(//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);

//  from Google Fonts.
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
$century-gothic: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
