body {
  background-color: $body_bg_color;
  margin:0px;
}

.l-header {
  padding: $vspace 0px 0px 0px;
  background-size: cover;
  color: $white;
  position: relative;

  .container {
    @include container;
  }
}

.l-branding {
  text-align:center;
  padding-bottom:20px;
  @include breakpoint($medium) {
    text-align:left;
    padding-bottom:0px;
  }
}


.l-nav {
  background-color:$purple;
  .region-header {
    //@include container;
  }
}


div#block-search-form {
  display:none;
  @include breakpoint($medium) {
  float: right;
  display:block;
  }
}