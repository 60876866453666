
.tabs--primary {
	li {
		margin-right: 0.5em;

		a {
			background: $green;
			color: $lightGray;
			border-top-right-radius: 10px;

			// @include transition(color, $transitionTime);
			// @include transition(border-radius, $transitionTime);

			&:hover {
				color: $white;
				background: $green;
				border-radius: 0;
			}

			&.active {
				background: lighten($green, 10%);
			}
		}
	}
}

//Main Menu Styling
.navigation-wrapper {
	background-color: $purple;
}

#block-superfish-1 {
@include container;
padding:20px;
@include breakpoint($medium) {
	padding:0px;
}
}

.l-region--sidebar-first {
	.menu {
		padding: 0;

		li {
			list-style-type: none;
			list-style-image: none;

			a {
				text-decoration: none;
				padding: 10px 0;
				padding-left: 20px;
				display:block;
				border-top: 1px solid #ccc;
			}

			ul {
				padding-left: 20px;
				li a {
					border-top: 0;
				}
			}
		}
	}
}

.sf-main-menu {
	li {
		float: left;

		a {
			padding: 15px 15px;
			color: #fff;
			text-decoration: none;
			font-size: 0.8em;

			&:hover,&:active {
				background-color: $green;
				color: #fff;
			}
		}

		ul {
			li a {
				background: $green;
				&:hover {
					background: $purple;
				}
			}
		}
	}

	li:hover > ul,
	li.sfHover > ul {
		//top: 2.7em;
	}
}

.sf-menu li li.sfHover > ul {
	left:6em;
}

@media screen and (min-width: 60em) {
	.sf-main-menu {
	li {
		float: left;
		span {
			padding: 15px 15px;
			&:hover {
				background-color: $green;
			}
		}
		a {
			padding: 15px 25px;
			color: #fff;
			text-decoration: none;
			font-size: 0.9em;

			&:hover {
				background-color: $green;
				color: #fff;
			}
		}

		ul {
			li a {
				background: $green;

				&:hover {
					background: $purple;
				}
			}
		}
	}

	li:hover > ul,
	li.sfHover > ul {
		top: 2.7em;
	}
}
}
.sf-menu ul {
	width:18em;
}

.sf-menu.sf-horizontal.sf-shadow ul {
	background-color: $purple;
	background-image: none;
	border-radius: 0;
	padding: 0 !important;
}

a.sf-depth-4 {
	position: relative;
	left:348px;
}

//Responsive menus
.responsive-menus.responsified {
	span.toggler {
		background: $purple;
		//@include border-radius(0);
		box-shadow: none;
		padding: 10px;
	}

	ul.responsive-menus-simple {
		background: $purple;
		//@include border-radius(0);
		box-shadow: none;

		li {
			a {
				text-decoration: none;

				&:hover {
					background: $green;
				}
			}

			ul {
				width: 100% !important; //Overrides the inline style generated by superfish
			}
		}
	}
}

//More overrides
.responsive-menus.responsified .responsive-menus-simple>li.last a {
	border-bottom: 1px solid rgba(255,255,255,0.5);
}

.mean-container .mean-bar {
	background-color: $purple;
	position:absolute;
	top:80px;
}

.mean-container .mean-nav ul li a {
	background-color: $purple;
			&:hover {
				background-color: $darkPurple;
				color: #fff;
			}
}

.mean-container .mean-nav ul li a.mean-expand {
	height:26px;
	background-color: $green;
			&:hover {
				background-color: $darkGreen;
				color: #fff;
				opacity: 1;
			}
}

.mean-container .mean-nav ul li li a {
	background-color: $green;
	opacity: 1;
		&:hover {
				background-color: $darkGreen;
				color: #fff;
				opacity: 1;
			}
}

// DL Menu

.dl-menuwrapper button {
	background: $purple;
}
.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active, .dl-menuwrapper ul {
    background: $purple;
}

// Footer Menu.
.l-footer ul.menu {
	padding-left: 20px;
	margin: 0;

	li.leaf {
		list-style-image: none;
		list-style-type: disc;

		a {
			display: block;
			padding: 3px 0;
			// border-bottom: dotted 1px #ccc;
			text-transform: uppercase;

			&:link,
			&:visited {
				text-decoration: none;
				color: #f5f5f5;
			}

			&:hover,
			&:focus,
			&:active {

			}
		}
	}
}
