.block--aggregator {
	ul {
		padding-left: 0;

		li {
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: dotted 1px #ccc;
			list-style: none;

			a:link {
				text-decoration: none;
			}
		}
	}
	.more-link a {
		display: inline-block;
	}
}
