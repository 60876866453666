.node-type-congregation {
	h1.title {
		position:relative;
		top:0px;
		color: $dkgray !important;
		text-shadow: none;
	}
	.l-content {
		@include container;
		padding:0px 20px;
	}
}