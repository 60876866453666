.search-block-form {
    display: none;
    }

@media screen and (min-width: 44em) {
	.search-block-form {
    display:block;
    float: right;
    padding: 20px 10px;
}
}

input#edit-search-block-form--2 {
    border-color: #dedede;
    border-style: solid;
    padding: 10px;
    width:50%;
}

.search-result__info {
	display: none;
}