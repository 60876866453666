.l-secondary {
  //padding: ($vspace * 2) $hspace;
  //background-color:$green;
  // background-image: url(../images/elca_logo.png);
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: right;
  background-color: #878888;
  &:before {
    background-color: rgba(53,137,77,0.5);
  }
  &:after{
    background-color: $green;
  }
  .container {
    @include container;
  }
  .block h2:after {
    //content: " Submenu";
}
}
