.page-calendar-list {
    .view-calendar {
    @include container;
    h2 {
        margin-bottom:10px;
    a {
        color: $green;
    }
    }
    .views-row {
        padding: 20px 0px;
    }
    .views-field-field-date {
        font-weight: bold;
        padding-bottom: 10px;
    }
    .views-field-view-node {
        padding-top: 10px;
    }
    .view-header {
       // float:right;
    }
}
}

.view-calendar {
    .views-field-field-date {
        font-weight: bold;
        padding-bottom: 10px;
    }
}


.view-calendar-2 {
    @include container;
    .cal-btn {
        //float:right;
    }
}

