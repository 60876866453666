.banner-text {
	padding: 10px 20px;
	margin: 0;

	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;

	h2 {
		color: #fff;
	}
}

.owl-page span {
	height: 20px;
	width: 20px;
}

@media screen and (min-width: 704px) {
	.banner-text {
		position: absolute;
		bottom: 20px;
		right: 20px;

		padding: 10px 20px 20px;
		width: 31%;
	}
}
@media screen and (min-width: 1120px) {
	.banner-text {
	//	width: 23%;
	}
}

.view-banner {
	padding-top:20px;
}
