.region-header {
    background-color: $purple;
    //padding:20px;
}

.l-title {
    @include container;
}

h1.title {
    position: absolute;
    font-family: $body_font;
    top: 1.5em;
    text-align: center;
    width: 100%;
    font-size: 2em;
    color: $white !important;
    //text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
    //text-transform: capitalize;
    @include breakpoint($medium) {
        font-size: 2.8em;
        text-align: left;
        //margin-left:20%;
    }
}
