*,
*:before,
*:after {
  box-sizing: border-box;
}

$tab: 44em; // At 44em,
$tab-columns: 12; // use 12 columns.
$desk: 70em; // At 70em,
$desk-columns: 16; // use 16 columns.

.l-header,
.l-main,
.l-footer {
  @include clearfix();
}

@include breakpoint($xsmall) {}

@include breakpoint($small) {}

@include breakpoint($medium) {
  // Two Sidebars.
  .two-sidebars {
    .l-content {
      @include span(isolate 6 at 4);
    }
    .region-sidebar-first {
      @include span(3);
    }
    .region-sidebar-second {
      @include span(last 3);
    }
  }
  // Left/First Sidebar.
  .one-sidebar.sidebar-first {
    .l-content {
      @include span(last 8);
    }
    .region-sidebar-first {
      @include span(4);
    }
  }

  // Right/Second Sidebar.
  .one-sidebar.sidebar-second {
    .l-content {
      @include span(8);
    }
    .region-sidebar-second {
      @include span(last 4);
    }
  }

  // HEADER regions.
  .l-branding {
    @include span(5);
    @include container;
  }
  .region-navigation {
    @include span(last 7);
   // @include container;
  }
  .region-header {
    @include span(12);
  }


  // FOOTER Regions.
  .l-footer .container {
    @include container;
  }
  .region-footer {
    @include span(12);
  }
  .region-footer-a {
    @include span(3.5);
  }
  .region-footer-b {
    @include span(4);
  }
  .region-footer-c {
    @include span(4);
  }
}

@include breakpoint($large) {}

@include breakpoint($xlarge) {}
