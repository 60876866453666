a.button-green {
  background-color: $green;
  padding: 10px 30px;
  color: white;
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
  &:hover {
      background-color: $green;
      color:white;
      opacity: 0.8;
  }
}

a.button-brown {
  background-color: $brown;
  padding: 10px 30px;
  color: white;
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
  &:hover {
      background-color: $brown;
      color:white;
      opacity: 0.8;
  }

}

a.button-blue {
  background-color: $deepblue;
  padding: 10px 30px;
  color: white;
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
  &:hover {
      background-color: $deepblue;
      color:white;
      opacity: 0.8;
  }
}

