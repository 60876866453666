.paragraphs-items {
    padding: 25px 0px;
    
    .field-item {
    padding:25px 0px;
    }

    .ds-2col-fluid {
            padding:0px 25px;
    }
    .paragraphs-item-two-even-columns {
        padding:0px 20px;
         > .group-left,
         > .group-right
         {
             float:left;
             width:100%;
         }
        @include breakpoint($medium) {
            @include container;
             > .group-left,
             > .group-right
             {
                float: left;
                width: 50%;
            }
             > .group-left {
                padding-right:30px;
            }
        }
    }

    .group-one-column {
        > .group-left,
        > .group-right
        {
            float:left;
            width:100% !important;
            padding: 0px 20px;
        }
    }
    .field-name-field-text-area-one-column {
        color: white;
        font-size: 1.5em;
        padding: 60px;
        text-shadow: 1px 1px 2px black;
        position: absolute;
        top: 0;
        width:100%;
        h2,h3,h4,h5 {
            color:white;
            text-shadow: 1px 1px 2px black;
        }
        @include breakpoint($medium) {
            padding:60px 120px;
        }
    }
    .field-name-field-background-image {
        //height: 500px;
        background-size:auto;
        background-attachment:fixed;
        @include breakpoint($medium) {
            background-size: cover;
            background-attachment: fixed;
        }
    }
    .paragraphs-item-one-column {
        //height:500px;
        position: relative;
    }
    .bgimage1 a {
        background-color: #0F7A96;
        padding: 10px 30px;
        color: white;
        display: inline-block;
        opacity: 0.8;
        transition: 0.3s;
        &:hover {
            background-color: #0F7A96;
            color:white;
            opacity: 1;
        }
    }
    .bgimage1 .field-name-field-background-image {  // background image - 500px Tall
        height:750px;
        @include breakpoint($medium) {
            height: 600px;
        }
        @include breakpoint($large) {
            height: 500px;
        }
    }
    .bgimage2 .field-name-field-background-image {  // background image - 750px Tall
        height:850px;
        @include breakpoint($medium) {
            height: 800px;
        }
        @include breakpoint($large) {
            height: 700px;
        }
    }
    .bgcolor-blue {  // background blue
        background-color: #4c599e;
        height: 800px;
        @include breakpoint($medium) {
            height: 600px;
        }
        @include breakpoint($large) {
            height: 500px;
        }
    }
    .bgwhite-nospace {  // background white
        color: #333;
        font-size: 1em;
        padding: 60px;
        text-shadow: none;
        //position: absolute;
        top: 0;
        //width:100%;
        font-color: $body_font;
        @include breakpoint($medium) {
            //height: 600px;
        }
        @include breakpoint($large) {
            //height: 500px;
        }
    }
    .embedded-video iframe {
        width: 100%;
        display: block;
    }
}