ul.main-menu,
ul.sf-main-menu {

  li a {
    display: block;
    padding: 25px 15px;
    text-decoration: none;

    &:link,
    &:visited {
      color: $menu;
    }

    &:hover,
    &:focus {
      color: $menu_hover;
    }

    &:active,
    &.active {
      color: $menu_active;
    }
  }
  ul {
    margin-top:20px;
    overflow: visible !important;
    li a {
      padding: 15px 25px;
    }
  }

}

a.sf-depth-3 {
  margin-left: 13em;
  width:18em;
}