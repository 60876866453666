// ------------------------------------
// Brand Colors.
// ------------------------------------
$brand_blue: #0F7A96;


// ------------------------------------
// Generic Colors.
// ------------------------------------

//See ECLA Brandmark quick reference PDF
$green: #006341;
$red: rgb(227, 27, 35); //E31B23
$darkRed: darken($red, 10%);
$purple: #4c599e;
$darkPurple: darken($purple, 10%);
$yellow: #d7a82c; 
$darkYellow: darken($yellow, 10%);
$green: #36894d; 
$darkGreen: darken($green, 10%);
$blue: #0F7A96;
$darkBlue: #2a2a4e;
$ltblue: #50B0C9;
$orange: #C2531A;

$brown: #9F4427;
$deepblue: #252F56;


// ------------------------------------
// Generic Neutral Colors.
// ------------------------------------
$black: #000;
$white: #ffffff;
$smoke: #f5f5f5;

$ltgray: #eee;
$lightGray: #eee;
$mdgray: #777;
$gray: #ccc;
$dkgray: #333333;
$bodyBg: #f2f2f2;
$offGray: #7f9498;

//Misc Global Styles
$transitionTime: 0.2s; //Default transition time




