.view-call-process {

	h3 {
		text-align: center;
		padding: 10px;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		background-color: #f5f5f5;
	}

	.views-row {
		padding: 20px;

		&:nth-child(odd) {
			background-color: #f5f5f5;
		}
	}
	
	.views-field-edit-node {
		text-align: right;
	}
}
