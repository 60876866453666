.breadcrumb {
  margin: 10px $hspace;
  display: block;
  font-size: 0.8em;

  ol {
    display: block;
    li {
      display: inline-block;
    }
  }

  a {
    text-decoration: underline;

    &:link,
    &:visited {
      color: $green;
    }

    &:hover {
      color: $green;
      text-decoration: none;
    }

    &:active,
    &.active {
      color: $green;
      text-decoration: none;
    }
  }
}

ol.breadcrumb--list {
		margin-block-start:0em;
		padding-left: 0px;
}
