.front {
  background:#f2f2f2;
  .l-header {
    background:white;
  }
  .view-banner {
    padding-top:0px !important;
  }
  .page-banner {
    height:200px;
    background-attachment:initial;
    background-size: contain;
    background-repeat: no-repeat;
    @include breakpoint($medium) {
    height:500px;
    background-attachment: fixed;
    background-size: cover;
    background-position: bottom;
    }

  }
  h1.title {
    //display: none;
  }
  ol.breadcrumb--list {
    display: none;
  }
#block-views-calendar-block, 
#block-block-11,
#block-block-6 {
  float:left;
  box-sizing: border-box;
  padding:0px;
  width:100%;
  background-color: #fff;
  margin:20px 5px;
  display: block;
  position:relative;
  .content p {
    padding:0px 20px;
  }
  h2 {
    background-color: rgba(255, 255, 255, 0.8);
    height: 60px;
    padding-top: 15px;
    width: 100%;
    position: absolute;
    text-align: center;
    display: block;
    top:-20px;
    z-index: 100;
  }
  @include breakpoint($medium) {
    width:32%;
    height:700px;
  }
  img.home-3-column {
    //top: -16px;
    position: relative;
  }
  .view-content {
    padding: 0px 20px;
    .views-row {
      padding-bottom: 20px;
    }
    h3.field-content {
      padding: 0px;
      margin: 5px 0px;
      a {color:$purple;}
  }
  }
}
.region-content {
  background-image: url(../images/rainbow_flag_resized.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.region-top-content,
.region-bottom-content {
  @include container;
}

#block-block-13 {
  @include container;
  color:#fff;
  text-align:center;
  h2 {
    color:#fff;
    font-size:3em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  }
  padding:50px 0px;
  .content {
      font-size: 1.5em;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  }
}

.view-featured-events {
  border-top:0px;
  clear:both;
  .views-row {
    width:95%;
    box-sizing: border-box;
    padding:20px;
    background:#fff;
    border-radius: 2px;
    margin:10px;
    @include breakpoint($medium) {
      float:left;
      width:32%;
      margin:5px;
    }
  }

}

// .view-featured-events {
// 	margin-bottom: 40px;
// 	border-top: dotted 1px #ccc;
// 	padding-top: 20px;

// 	.views-row {
// 		margin-bottom: 20px;
// 		padding-bottom: 20px;
// 		border-bottom: dotted 1px #ccc;
// 	}
// 	.group-left {
// 		padding-right: 20px;
// 	}
// 	.field--name-node-link {
// 		// text-align: right;
// 		font-size: 0.8em;
// 		text-transform: uppercase;
// 	}
// 	.field--name-title h3 {
// 		margin: 0;
// 		padding: 0;
// 	}
// }
#block-views-featured-events-block {
  clear:both;
  padding-top: 20px;
  h2 {
    font-size: 3em;
    text-align:center;
  }
}

}