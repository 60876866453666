.view-conferences {
	@include container;
	.views-row {
		padding: 15px;

		&:nth-child(even) {
			background-color: #f5f5f5;
		}
	}

}

.view-call-process,
.view-employment-opportunities,
.view-staff,
.view-call-process {
	@include container;
	.views-row {
		padding: 15px;

	}

}