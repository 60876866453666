.view-staff, .view-staff-2021 {
	text-align: center;

	.dean-row {
		display: inline-block;
		width: 48%;
		overflow: hidden;
		vertical-align: top;
		padding: 10px;
		margin-bottom: 10px;
	}

	.staff-row {
		width: 48%;
		display: inline-block;
		vertical-align: top;
		padding: 10px;
		margin-bottom: 20px;

		.views-field-body {
			font-size: 0.8em;

			ul {
				padding: 0;
			}
			li {
				list-style: none;
				padding: 0;
				margin: 0;
			}
		}
	}

	h3 {
		background-color: #f5f5f5;
		padding: 10px;
	}

	.views-field-field-title,
	.views-field-field-congregation,
	.views-field-field-email,
	.views-field-body {
		font-size: 0.8em;

		li {
			text-align: left;
			margin-bottom: 5px;
		}
	}


	@include susy-breakpoint($tab) {

		.dean-row {
			width: 32%;
		}
	}

	@include susy-breakpoint($desk) {

		.dean-row {
			width: 25%;
		}
	}
}

