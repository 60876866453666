.l-footer {
  padding: $vspace $hspace;
  background: $footer_bg;
  color: $footer_color;

  h2 {
    color: $footer_color;
  }

  .region {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .container {
    @include container;
  }

  a {
    @include linkme($footer_link, $footer_link_hover, $footer_link_active);
  }

  .tithely-campus-selector {
    border-top: 0px !important;
}
}
