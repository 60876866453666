.view-employment-opportunities {
	.views-row {
		margin-bottom: 20px;
		padding: 10px 20px;
		
		.views-field-title h2 {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&:nth-child(odd) {
			background-color: #f5f5f5;
		}
	}
}
