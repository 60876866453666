// ------------------------------------
// Gloabl Vars.
// ------------------------------------

// Messages and feedback colors.
$error: red;
$warning: yellow;
$information: green;


// Body/HTML Settings.
$body_font: 'Open Sans', sans-serif; // Default font.
$body_fontsize: 16px; // Base or root font-size.
$body_lineheight: 1.5em;
$body_fontcolor: $dkgray; // Default font color.
$body_bg_color: $white; // Page background color.

// Titles and Headings.
$title_font: null; // defaulting to body font;
$title_fontcolor: $gray; // This is for the H1.
$title_fontweight: 300;

$heading_font: $title_font; // This is for the rest of the headings.
$heading_fontcolor: $purple; // darken($blue, 3%); // This is for the rest of the headings.
$heading_fontweight: 300;


// Butons and Links.
$button_bg_color: $ltblue;
$button_fontcolor: #ffffff;
$button_radius: 0;

$link: $blue; // Default link color.
$link_hover: $orange; // Default link hover.
$link_active: $blue; // Default link active and focus color.

$footer_link: #ffffff; // Footer link color.
$footer_link_hover: $red; // Footer link hover.
$footer_link_active: $orange; // Footer link color.

// Menus.
$menu: #fff; // Primary navigation color.
$menu_hover: #fff; // Primary navigation hover.
$menu_active: $menu_hover; // Primary navigation active and focus color.

$menu_bg: transparent; // Primary navigation background.
$menu_bg_hover: $gray; // Primary navigation hover background.
$menu_bg_active: $menu_bg_hover; // Primary navigation active and focus background.

$menu_font: null ; // Font used for primary navigation.
$menu_font_weight: 400;


// Layout and Structural Vars.
$header_bg:	#ffffff;
$header_font: null;
$header_fontcolor: $blue;

$main_bg: null; // The main content aera.

$footer_color: $white; // Font color.
$footer_bg: $darkPurple; // Background color.
$footer_size: 1em; // Text size.


// Misc stuff.
$border_color: $gray; // Gloabl border color.

$vspace: 20px; // Vertical spacing unit.
$hspace: 20px; // Horizontal spacing unit.


// Local Tasks
$tabs_toggle_bg: #fff;
$tabs_toggle_text: $body_fontcolor;

$tabs_primary_link: lighten($body_fontcolor, 25%);
$tabs_primary_link_active: $link;

$tabs_primary_text: $body_fontcolor;
$tabs_primary_text_active: $body_fontcolor;

$tabs_primary_bg: transparent;
$tabs_primary_bg_hover: mix($tabs_primary_link_active, white, 5%);
$tabs_primary_bg_active: mix($tabs_primary_link_active, white, 5%);

$tabs_secondary_text: $white;
$tabs_secondary_text_active: $white;

$tabs_secondary_bg: $blue;
$tabs_secondary_bg_active: $ltblue;


// Tables.
$table_cell_padding: 10px;
$row_even: $smoke; // lighten($blue, 30%) Table even row styles
$row_odd: transparent; // Table odd row styles
$table_header: $row_even; // Table header row styles
