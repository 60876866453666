.l-main {
    padding-bottom: $vspace;
    position: relative;
  
    .l-content {
      position: relative; // this is for the local tasks.
      p {
        line-height:2em;
      }
    }
  }
  

.content .field-name-body {
    @include container;
    padding:20px;
}

.tabs--primary-wrapper, ul.action-links, div.status {
    @include container;
}

.page-banner {
    height: 200px;
    background-size: cover;
    background-position: right;
    @include breakpoint($medium) {
      background-position: center;
      height: 275px;
    }
    
}

#block-addtoany-addtoany-button {
  text-align: center;
  padding: 20px;
}

.node-type-event .l-main {
  @include container;
  padding:0px 20px;
  h1.title {
    display:none;
  }
  h2 {
    font-size: 2em;
  }
  .field-name-field-date {
    font-weight: bold;
  }
  .field-name-addtoany {
    padding-bottom:20px;
    text-align: center;
  }
}